import { render, staticRenderFns } from "./AboutUsTab.vue?vue&type=template&id=93a4af68&scoped=true&"
import script from "./AboutUsTab.vue?vue&type=script&lang=js&"
export * from "./AboutUsTab.vue?vue&type=script&lang=js&"
import style0 from "./AboutUsTab.vue?vue&type=style&index=0&id=93a4af68&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93a4af68",
  null
  
)

export default component.exports